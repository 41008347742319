import React, { useContext } from 'react'
import { Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import {
  AsideLeft,
  ButtonWrapper,
  Container,
  ItemInfo,
  ItemTitle,
  PriceTag,
  Quantity,
  Wrapper,
  Amount,
} from './styles'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="60px"
    />
  ) : null

  const handleRemove = () => {
    const res = window.confirm('商品をカートから削除してもよろしいですか？')
    if (res) {
      removeLineItem(client, checkout.id, item.id)
    } else return
  }

  const total = item.variant.price * item.quantity

  {
    console.log(item)
  }

  return (
    <Wrapper>
      <Container>
        <AsideLeft>
          <Link to={`/product/${item.variant.product.handle}/`}>
            {variantImage}
          </Link>
          <ItemInfo>
            <ItemTitle>{item.title}</ItemTitle>
            <Quantity>数量: {item.quantity}</Quantity>
            <PriceTag>価格: {item.variant.price}</PriceTag>
            <Amount>合計: {total}</Amount>
          </ItemInfo>
        </AsideLeft>
        <ButtonWrapper>
          <button onClick={handleRemove}>削除</button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  )
}

export default LineItem
