import styled from '@emotion/styled'
import color from '../../../styles/color'
import { breakpoints } from '../../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 45px;
`

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
`

export const AsideLeft = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 12px;
  }
`

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
`
export const ItemTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 12px;
  color: ${color.BLACK};
`

export const Quantity = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
`

export const PriceTag = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
`
export const Amount = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
`

export const ButtonWrapper = styled.div`
  font-size: 14px;
  flex-shrink: 0;
  button {
    background-color: transparent;
    border: 1px solid #707070;
    border-radius: 2px;
    padding: 0 4px;
  }
`
