import React from 'react'
import Cart from '../components/Cart'
import { Container, Head } from '../styles/cartPage'
import SEO from '../components/seo'
import PageTransitionWhite from '../components/Animation/PageTransitionWhite'

const CartPage = () => (
  <>
    <SEO
      title="ショッピングカート"
      keywords={[`高天原`, `アクセサリー`, `天然石`, `accessory`, `stone`]}
    />
    <Container>
      <Head>ショッピングカート</Head>
      <Cart />
    </Container>
    <PageTransitionWhite />
  </>
)

export default CartPage
