import styled from '@emotion/styled'
import color from '../../styles/color'
import colors from '../../styles/color'
import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.m}px) {
    max-width: 1000px;
    margin: 0 auto;
  }
`

export const ItemWrapper = styled.div`
  border-top: 1px solid ${colors.LIGHT_GRAY};
  border-bottom: 1px solid ${colors.LIGHT_GRAY};
  padding-top: 30px;
  margin-bottom: 30px;
`

export const SubTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  color: #1c1c1c;
  font-weight: 700;
  text-align: right;
  margin-bottom: 24px;

  span {
    margin-right: 30px;
  }
`

export const Description = styled.div`
  text-align: right;
  font-size: 10px;
  color: #707070;
`

export const NoContent = styled.div`
  font-size: 15px;
  color: ${color.GRAY};
  text-align: center;
  margin-bottom: 24px;
`

export const BackLink = styled.div`
  font-size: 15px;
  text-align: center;
  margin-bottom: 60px;
  a {
    color: ${color.GRAY};
    border-bottom: 1px solid ${color.GRAY};
  }
`
