import styled from '@emotion/styled'

export const Container = styled.div`
  padding: 0 12px;
`

export const Head = styled.h1`
  font-size: 18px;
  text-align: center;
  color: #1c1c1c;
  margin: 33px 0;
`
