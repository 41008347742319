import React, { useContext } from 'react'

import LineItem from './LineItem'
import {
  BackLink,
  Description,
  ItemWrapper,
  NoContent,
  SubTotal,
  Wrapper,
} from './styles'
import StoreContext from '../../context/StoreContext'
import { Link } from 'gatsby'
import { ButtonWrapper } from '../../utils/styles'

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const isItem = checkout.lineItems.length !== 0

  if (!isItem)
    return (
      <>
        <NoContent>カートに商品がありません</NoContent>
        <BackLink>
          <Link to="/accessory">買い物を続ける</Link>
        </BackLink>
      </>
    )

  return (
    <Wrapper>
      <ItemWrapper>{lineItems}</ItemWrapper>
      <SubTotal>
        <span>小計</span>¥{checkout.subtotalPrice}
      </SubTotal>
      <Description>
        送料、消費税はチェックアウトの際に自動計算されます
      </Description>
      <ButtonWrapper>
        <button onClick={handleCheckout} disabled={!isItem}>
          ご購入手続きへ進む
        </button>
      </ButtonWrapper>
      <BackLink>
        <Link to="/productlist">買い物を続ける</Link>
      </BackLink>
    </Wrapper>
  )
}

export default Cart
